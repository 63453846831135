import * as React from "react";
import Slider from "react-slick";
import Footer from "../components/Footer/footer";
import { Head } from "../components/Header/head";
import { Navbar } from "../components/Navbar/navbar";
import "../styles/detailPage.css";
import { Carousel, Image, Modal } from "react-bootstrap";
import { navigate } from "gatsby-link";
import { SSRProvider } from "@react-aria/ssr";

const DetailPage = ({ location }) => {
  let data = null;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  console.log(location)
  const sliders = (arrayFotos) => {
    return arrayFotos.map((image, index) => {
      return <Image key={index} src={image} />;
    });
  };
  if (location && location.state && location.state.data) {
    data = location.state.data;

    return (
      <SSRProvider>
        <Head title="Posada Los Jerónimos - Servicios" />
        <Navbar />

        <div className="detail-wrapper">
          <h1 className="detail-title">{data.title}</h1>

          <p
            className="detail-description"
            dangerouslySetInnerHTML={{ __html: data.description }}
          ></p>
          <section className="carousel-wrapper"></section>
        </div>
        <div className="detail-slider">
          <Slider {...settings}>{sliders(data.imagesArray)}</Slider>
        </div>
        <Footer slim />
      </SSRProvider>
    );
  } else {
    return <></>;
  }
};

export default DetailPage;
